.noti-page {
  .webapp-version {
    color: grey;
    text-align: right;
    font-weight: bold;
    margin-top: 3em;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}
