.post-list {
  .header {
    background: rgb(0, 0, 0);
    color: white;

    .title {
      text-align: left;
    }
  }

  .list {
    .title:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .selected {
      background: rgba(100, 100, 100, 0.8);
    }

    .current {
      font-family: RixSGoB;
      color: #2671e1;
    }

    .none {
      font-family: RixSGoB;
      text-align: center;
      padding: 1.5em;
      color: #111111;
    }
  }

  .row {
    display: flex;
    height: 2em;
    align-items: center;

    @media screen and (max-width: 768px) {
      .selection {
        display: none;
      }

      .game {
        padding-left: 1.5em;
      }

      .title {
        display: inline-block;
        width: 8em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .datetime {
        display: none;
      }
    }
    border-bottom: 1px solid rgba(100, 100, 100, 0.3);
  }

  .row:last-child {
    border-bottom: none;
  }

  .unread {
    color: green;
    font-family: RixSGoB;
    font-weight: bold;
  }

  .selection {
    display: flex;
    width: 50px;
    align-items: center;
    justify-content: center;
  }

  .game {
    display: flex;
    min-width: 9em;
  }

  .title {
    display: inline-block;
    justify-content: flex-start;
    padding-left: 1em;
    padding-right: 2em;

    /* Ellipsis handling for the long text */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 25em;
  }

  .datetime {
    display: flex;
    width: 13em;
    justify-content: flex-start;
  }

  .control {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1em;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgb(150, 150, 150);
      padding: 0.5em;
      width: 5em;
      height: 1.5em;
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    justify-content: center;
  }
}

.selector {
  width: 15px;
  height: 15px;
  border: 2px solid red;
  border-radius: 5px;
}
