.page-nav {
  display: flex;
  justify-content: flex-start;
  padding: 2em 3em 1em 3em;

  .page {
    display: flex;
    justify-content: center;
    color: grey;
    padding: 0.5em;
    margin: 0 1em 1em 0;
    border: 2px solid rgb(120, 120, 120);
    background: rgba(100, 100, 100, 0.5);
  }

  .page:hover {
    background: rgba(150, 150, 150, 0.5);
  }

  .current {
    color: red;

    a {
      color: red;
      text-decoration: none;
    }
  }
}
