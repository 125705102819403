@import './font.scss';

body {
  margin: 0;
  font-family: RixSGoM, -apple-system, BlinkMacSystemFont, 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(10, 10, 10);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
