.post-detail {
  width: 80%;
  line-height: 1.4em;
  word-break: keep-all;

  @media screen and (max-width: 768px) {
    width: 85%;
    margin: 13px 0;
  }

  .mobile-header {
    display: none;

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      font-family: RixSGoB;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #333333;
      margin-bottom: 12px;

      .mobile-title {
        display: inline-block;
        width: 100%;
        text-overflow: unset;
        white-space: unset;
        padding: 0;
      }

      .mobile-datetime {
        float: right;
        width: 5em;
        text-align: right;
      }
    }
  }
}
