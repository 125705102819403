@font-face {
  font-family: 'RixSGoB';
  font-weight: 400;
  src: url('https://lolstatic-a.akamaihd.net/riot-kr/fonts/RixSGoB.woff') format('woff');
}

@font-face {
  font-family: 'RixSGoM';
  font-weight: 400;
  src: url('https://lolstatic-a.akamaihd.net/riot-kr/fonts/RixSGoM.woff') format('woff');
}
