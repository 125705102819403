.App {
  display: flex;
  background: url('https://lolstatic-a.akamaihd.net/rso-login-page/2.0.3/assets/riot_desktop_background_2x.jpg');
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  color: rgb(10, 10, 10);
}

.App-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 850px;
  max-width: 80%;
  margin: 2em;
  padding: 3em 5em 3em 5em;
  background: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
}
